<template>
  <div class="hero-case-study">
    <LetsChat></LetsChat>
    <div class="grid-container">
      <div class="hero__content logo-max">
        <a href="http://maxinspireconcept.com/" target="_blank">
          <img src="@/assets/casestudy/logo-max-finale.svg" alt="Max Inspire"></a>
        <span class="sep"></span>
        <p>Young team with significant experience in Architectural design. They offer a large
          package of services from project management, interior - exterior design or project
          consultancy for your desired building.</p>
      </div>
    </div>

    <div class="laptop-img max-laptop">
      <div class="grid-container">
        <img src="@/assets/casestudy/max-preview.png" alt="Max Inspire">
      </div>
    </div>

    <div class="container-about max-about">
      <div class="grid-container">
        <img src="@/assets/casestudy/max-diamond.svg" alt="Max Inspire">
        <h3>About the project</h3>
        <p>The flow for this project was very fast and solid. The client wanted to present not
          only his new firm, but also his team's significant experience in architectural design. <br/>We also
          designed the website in a clean but elegant way, without over loading it with info
          about what the Max Inspire team is doing. This info you can best get from the portfolio
          page.</p>
      </div>
    </div>

    <div class="container-typography">
      <div class="grid-container">
        <div class="container-typography__content">
          <h3>Typography</h3>
          <div class="font-typo">
            <img src="@/assets/casestudy/source-sans-pro-font.svg" alt="Max Inspire Font">
            <h3><span style="color:#f15a24">Co</span><span style="color:#006884">lo</span><span
                style="color:#222222">rs</span></h3>

            <div class="colors">
              <div class="fcolor max-p">Primary</div>
              <div class="scolor max-s">Secondary</div>
              <div class="tcolor max-t">Third</div>
            </div>

            <span class="sep vertical color-tag"></span>

            <div class="color-codes">
              <div class="ccodes">
                <p class="max-primary">#f15a24</p>
                <p class="max-secondary">#006884</p>
                <p class="max-third">#222222</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-nav-projects">
      <div class="grid-container">
        <div class="container-nav-projects__content">
          <h3>Related projects</h3>

          <div class="container-nav-projects-left">
            <router-link :to="{ name: 'BellaClean'}" class="left">
              <img src="@/assets/casestudy/btn-bella.png" alt="Bella Clean">
            </router-link>
          </div>

          <div class="container-nav-projects-right">
            <router-link :to="{ name: 'Careful'}" class="right">
              <img src="@/assets/casestudy/btn-careful.jpg" alt="Careful">
            </router-link>
          </div>
          </div>
      </div>
    </div>

    <ContactUs color="#006884"></ContactUs>
    <Footer></Footer>
  </div>
</template>

<script>
import ContactUs from "../../components/ContactUs";
import Footer from "../../components/Home/Footer";
import LetsChat from "../../components/LetsChat";

export default {
  name: "MaxConcept",
  components: {LetsChat, Footer, ContactUs}
}
</script>

<style lang="scss">
.logo-max img {
  max-width: 320px;
}

.max-laptop {
  background-color: #006884;
}
.max-p {
  background-color: #f15a24;
}
.max-primary {
  color:#f15a24;
}
.max-s {
  background-color: #006884;
}
.max-secondary {
  color: #006884;
}
.max-t {
  background-color: #222222;
}
.max-third {
  color: #222222;
}
.container-nav-projects__content h3 {
  display: block;
  background: rgb(58, 58, 58);
  background: linear-gradient(125deg, rgba(58, 58, 58, 1) 50%, #006884 60%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

</style>